<template>
  <section class="my-5">
    <v-dialog v-model="modal" max-width="450px" @click:outside="cerrarModalS()">
      <v-card>
        <v-card-title class="text-h5 secondary--text">
          Suspender proceso
        </v-card-title>

        <v-card-text>
          <p class="text-h6 primary--text">¿Desea suspender este proceso?</p>
          <h4>Proceso</h4>
          <h5>{{ procesoSuspender.codigo_proceso }}</h5>
          <h5>{{ procesoSuspender.nombre_proceso }}</h5>
          <h5>{{ procesoSuspender.FormaContratacion?.nombre }}</h5>
          <h5>
            $
            {{
              Intl.NumberFormat("en-US", {
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(procesoSuspender.suma_mercancia)
            }}
          </h5>

          <v-row class="mt-2">
            <v-col cols="12">
              <v-file-input
                label="Justificación"
                outlined
                prepend-inner-icon="mdi-paperclip"
                prepend-icon=""
                append-icon=""
                accept="application/pdf"
                v-model="documento"
                :error-messages="documentoError"
                @blur="$v.documento.$touch()"
                @input="$v.documento.$touch()"
              ></v-file-input>
            </v-col>
          </v-row>

          <v-card-actions>
            <v-row>
              <v-col cols="12" sm="12" md="3">
                <v-btn
                  block
                  outlined
                  color="secondary"
                  @click="cerrarModalS"
                  >Volver</v-btn
                >
              </v-col>
              <v-col cols="12" sm="12" md="3">
                <v-btn block color="amber lighten-3" @click="suspender()"
                  ><v-icon class="mx-2">mdi-pause-circle</v-icon>Suspender</v-btn
                >
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import LegacyValidations from "@/utils/legacy-validations";
import { required } from "vuelidate/lib/validators";

function fileTypeValid(value) {
  if (!value) {
    return true;
  }
  let extension = /(\.pdf)$/i;
  let file = value;
  return extension.test(file.name);
}

function fileNameValid(value) {
  if (!value) {
    return true;
  }
  let regAlfaNumPdf = /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ\-_\s]+(?=\.pdf$)/;
  let file = value;
  return regAlfaNumPdf.test(file.name);
}

export default {
  props: ["modal", "proceso"],
  data: () => ({
    procesoSuspender: {},
    documento: null,
  }),
  validations: {
    documento: {
      required,
      fileTypeValid,
      fileNameValid,
      fileSizeValid: LegacyValidations.maxFileSizeRule(),
    },
  },
  computed: {
    documentoError() {
      const errors = [];
      if (!this.$v.documento.$dirty) return errors;
      !this.$v.documento.required && errors.push("Debe de subir un documento");
      !this.$v.documento.fileTypeValid &&
        errors.push("El archivo debe de ser PDF");
      !this.$v.documento.fileNameValid &&
        errors.push("El nombre del archivo no debe de contener caracteres especiales");
      !this.$v.documento.fileSizeValid && LegacyValidations.pushToErrorsArray(errors);
      return errors;
    },
  },
  methods:{
    cerrarModalS(){
        this.documento = null
        this.$emit("cerrar")
    },
    async suspender(){

        const formData = new FormData()
        formData.append("doc_justificacion", this.documento)
        const response = await this.services.PacProcesos.suspenderProceso(
            this.$route.params.idProceso,
            formData
        )
        if(response.status === 204) {
            this.temporalAlert({
                show:true,
                type:"success",
                message:"El proceso está suspendido"
            })
            this.cerrarModalS()
        }
    }
  },
  watch: {
    proceso() {
      this.procesoSuspender = this.proceso;
    },
  },
  created() {
    this.procesoSuspender = this.proceso;
  },
};
</script>

<style></style>
