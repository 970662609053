<template>
  <Fragment>
    <v-row v-if="puedeDescargarlo">
      <v-col>
        <v-btn color="secondary" @click="descargarReportePip"
          >Descargar PIP</v-btn
        >
      </v-col>
    </v-row>
    <v-row style="height: auto">
      <v-col>
        <v-data-table
          :headers="filteredHeader"
          hide-default-footer
          :items="mapEtapas"
          :items-per-page="mapEtapas.length"
        >
          <template v-slot:[`item.fecha_inicio`]="{ item }">
            <vc-date-picker
              v-model="item.fecha_inicio"
              mode="dateTime"
              locale="es"
              :popover="config_calendar"
              :min-date="fecha_actual"
              :minute-increment="5"
            >
              <template v-slot="{ inputEvents }">
                <v-text-field
                  class="px-2 py-1 border roundend focus:outline-none focus:border-blue-300"
                  :value="getFechaInicioParametrizable(item.fecha_inicio)"
                  readonly
                  :disabled="hideActions || !permiteEdicion"
                  v-on="inputEvents"
                  hide-details
                  outlined
                />
              </template>
            </vc-date-picker>
          </template>
          <template v-slot:[`item.fecha_fin`]="{ item }">
            <vc-date-picker
              v-model="item.fecha_fin"
              mode="dateTime"
              locale="es"
              :popover="config_calendar"
              :min-date="item.fecha_inicio"
              :minute-increment="5"
            >
              <template v-slot="{ inputEvents }">
                <v-text-field
                  :disabled="hideActions || !permiteEdicion"
                  class="px-2 py-1 border roundend focus:outline-none focus:border-blue-300"
                  :value="getFechaInicioParametrizable(item.fecha_fin)"
                  v-on="inputEvents"
                  outlined
                  readonly
                  hide-details
                />
              </template>
            </vc-date-picker>
          </template>
          <template v-slot:[`item.acciones`]="{ item }">
            <v-tooltip right left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  small
                  :disabled="!permiteEdicion"
                  @click="
                    actualizarNuevoPip(
                      item.fecha_inicio,
                      item.fecha_fin,
                      item.id_etapa,
                      false
                    )
                  "
                >
                  <v-icon color="secondary">mdi-send-variant</v-icon>
                </v-btn>
              </template>
              <span> Actualizar etapa</span>
            </v-tooltip>
          </template>
        </v-data-table>
        <div v-if="mapEtapas?.length <= 4" style="height: 300px"></div>
      </v-col>
    </v-row>
  </Fragment>
</template>

<script>
import { requiredIf } from "vuelidate/lib/validators";
import { VueEditor } from "vue2-editor";
import moment from "moment";
import { Fragment } from "vue-fragment";

function dateValidation() {
  return (
    moment(this.fecha_inicio).format("YYYY-MM-DD HH:mm") <
      moment(this.fecha_fin).format("YYYY-MM-DD HH:mm") ||
    this.disableFechas == true
  );
}

export default {
  name: "PipProcesoComponent",
  props: {
    etapas: {
      type: Array,
      defaultValue: [],
    },
    hideActions: {
      type: Boolean,
      defaultValue: () => false,
    },
    action: {
      type: Function,
    },
    permiteEdicion: {
      type: Boolean,
    }
  },
  components: { VueEditor, Fragment },
  data: () => ({
    disableFechas: false,
    headers: [
      {
        text: "Documento",
        value: "documento",
      },
      {
        text: "Fecha de carga",
        value: "fecha_carga",
      },
      {
        text: "Acciones",
        value: "acciones",
      },
    ],
    TABLE_HEADERS: [
      {
        text: "Etapa",
        value: "nombre_etapa",
        sortable: false,
      },
      {
        text: "Fecha de inicio",
        value: "fecha_inicio",
        sortable: false,
        align: "center",
      },
      {
        text: "Fecha de fin",
        value: "fecha_fin",
        sortable: false,
        align: "center",
      },
      {
        text: "Cantidad de días",
        value: "cantidad_dias",
        sortable: false,
        align: "center",
      },
      {
        text: "Acciones",
        value: "acciones",
        sortable: false,
        align: "center",
      },
    ],
    etapa: null,
    showModalConfig: false,
    fecha_inicio: null,
    fecha_fin: null,
    config_calendar: {
      visibility: "click",
    },
    fecha_actual: "",
    fecha_minima: "",
    fecha_maxima: "",
    id_phase: "",
    phase_name: "",
    horas_disponibles_inicio: [],
    horas_disponibles_fin: [],
    documento: null,
    isDocUpdate: false,
    editMode: false,
    document_path: "",
    document_name: "",
    showModal: false,
    showModalEtapa3: false,
    documentos: [],
    content: "",
    fases: [],
    id_etapa: null,
  }),
  validations: {
    fecha_inicio: {
      required: requiredIf(function () {
        return this.showModalConfig;
      }),
      dateValidation,
    },
    fecha_fin: {
      required: requiredIf(function () {
        return this.showModalConfig;
      }),
      dateValidation,
    },
  },
  watch: {
    fecha_inicio() {
      this.getFechaInicio;
    },
    fecha_fin() {
      this.getFechaFin;
    },
  },
  computed: {
    puedeDescargarlo() {
      return this.haveRoles(["ROLE_UFI", "ROLE_UACI"]);
    },
    filteredHeader() {
      if (this.hideActions) {
        return this.TABLE_HEADERS.filter(
          (target) => target.value !== "acciones"
        );
      } else return this.TABLE_HEADERS;
    },
    mapEtapas() {
      return this.etapas?.map((element) => {
        let cantidadDias = 0;
        if (element.fecha_hora_inicio && element.fecha_hora_fin) {
          const fechaInicio = moment(element.fecha_hora_inicio);
          const fechaFin = moment(element.fecha_hora_fin);

          cantidadDias = fechaFin.diff(fechaInicio, "days");
        }

        return {
          id_etapa: element.id,
          id: element.id_etapa,
          nombre_etapa: element.nombre,
          fecha_inicio: element.fecha_hora_inicio
            ? element.fecha_hora_inicio
            : "",
          fecha_fin: element.fecha_hora_fin ? element.fecha_hora_fin : "",
          cantidad_dias: cantidadDias,
        };
      });
    },
    getFechaInicio() {
      return this.fecha_inicio
        ? moment(this.fecha_inicio, "YYYY-MM-DD HH:mm").format(
            "DD/MM/YYYY hh:mm A"
          )
        : "";
    },
    getFechaFin() {
      return this.fecha_fin
        ? moment(this.fecha_fin, "YYYY-MM-DD HH:mm").format(
            "DD/MM/YYYY hh:mm A"
          )
        : "";
    },
  },
  methods: {
    async descargarReportePip() {
      const { data, headers } = await this.services.PacProcesos.imprimirPdfPip(
        Number(this.$route.params.idProceso)
      );
      const blob = new Blob([data], {
        type: headers["content-type"],
      });
      const file = new File(
        [blob],
        "etapas plan de implementación del proceso",
        {
          type: headers["content-type"],
        }
      );

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(file);
      link.download = file.name;
      link.click();
    },
    clean() {
      this.etapa = null;
      this.fecha_inicio = null;
      this.fecha_fin = null;
      this.documento = null;
      this.content = "";
      this.$v.fecha_inicio.$reset();
      this.$v.fecha_fin.$reset();
    },
    getCurrentDay() {
      const date = new Date(Date.now());
      this.fecha_actual = moment(date).format("YYYY-MM-DD HH:mm");
    },
    getFechaInicioParametrizable(fecha) {
      return fecha
        ? moment(fecha).format("DD/MM/YYYY hh:mm A")
        : "Pendiente de configurar";
    },
    async actualizarNuevoPip(
      fecha_hora_inicio,
      fecha_hora_fin,
      id_etapa,
      envioPorEvento = true
    ) {
      if (
        envioPorEvento &&
        ([null, undefined, ""].includes(fecha_hora_inicio) ||
          [null, undefined, ""].includes(fecha_hora_fin))
      ) {
        return;
      }
      if (
        [null, undefined, ""].includes(fecha_hora_inicio) ||
        [null, undefined, ""].includes(fecha_hora_fin)
      ) {
        this.pushAppMessage({
          show: true,
          type: "warning",
          message: "Rellene ambas fechas",
        });
        return;
      }
      const {
        data: { mensaje },
      } = await this.services.PacProcesos.actualizarEtapaProceso(
        Number(this.$route.params.idProceso),
        Number(id_etapa),
        {
          fecha_hora_inicio: moment(fecha_hora_inicio).format(
            "YYYY-MM-DD HH:mm:ss"
          ),
          fecha_hora_fin: moment(fecha_hora_fin).format("YYYY-MM-DD HH:mm:ss"),
        }
      );
      this.$emit("actualizarEtapa");
      this.pushAppMessage({
        show: true,
        type: "success",
        message: mensaje,
      });
    },
  },
  async created() {
    this.getCurrentDay();
  },
};
</script>
