<template>
  <section>
    <v-card>
      <v-card-title v-if="procesoData?.participantes?.length > 0">
        <span class="secondary--text">Participantes del proceso y sus ofertas</span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="12">
            <v-text-field v-model="busqueda" label="Buscar por nombre" outlined dense clearable></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters v-if="procesoData?.participantes?.length > 0" class="FixedHeightContainer Content">
          <v-col class="d-flex" style="flex-direction: column" cols="12" sm="12" md="6" lg="3"
            v-for="participante in ofertas_list" :key="participante.id_proveedor">
            <v-card class="mb-1 flex-grow-1 " outlined elevation="8">
              <div class="d-flex justify-space-between align-center ma-4">
                <span class="text-subtitle-1">{{
                  participante?.proveedor
                }}</span>
                <v-btn @click="goToPerfil(participante.id_usuario)" icon color="secondary" class="text-capitalize">
                  <v-icon color="secondary">mdi-card-account-details</v-icon>
                </v-btn>
              </div>
              <v-card v-for="(oferta, index) in participante?.ofertas" :key="index" elevation="8" class="mb-2 mx-4"
                outlined>
                <v-card-title>
                  {{
                    oferta?.id_tipo_oferta == 1
                      ? "Oferta técnica"
                      : " Oferta económica"
                  }}
                </v-card-title>
                <v-card-subtitle>
                  <v-list dense flat tile>
                    <v-list-item>
                      <v-list-item-content>
                        Fecha recepción:<span class="grey--text">{{
                          moment(
                            oferta?.fecha_recepcion
                          ).format("DD/MM/YYYY HH:mm a")
                        }}</span>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        Fecha primera carga:<span class="grey--text">{{
                          moment(oferta?.fecha_creacion).format(
                            "DD/MM/YYYY HH:mm a"
                          )
                        }}</span></v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        Fecha última actualización del registro:<span class="grey--text">{{
                          moment(oferta?.fecha_actualizacion).format(
                            "DD/MM/YYYY HH:mm a"
                          )
                        }}</span></v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        Subida por:<span class="grey--text">{{ oferta?.nombre }} (
                          {{ oferta.proveedor ? "Proveedor" : "Institución" }})
                          <br />
                          Con correo: {{ oferta?.email }}</span>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card-subtitle>
                <v-card-actions class="pt-0 pb-0 mt-0">
                  <v-row no-gutters>
                    <v-col>
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn v-bind="attrs" v-on="on" class="ma-2" color="secondary" dark @click="
                            descargarDocumento(oferta?.documento_oferta)
                            ">
                            <v-icon x-large>mdi-download</v-icon>
                          </v-btn>
                        </template>
                        <span>descargar oferta</span>
                      </v-tooltip>
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn v-bind="attrs" v-on="on" class="ma-2" color="secondary" dark @click="
                            (dialog = true),
                            (participaciones = oferta?.participacionLotes)
                            ">
                            <v-icon x-large>mdi-briefcase-search-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>Ver participación por lote</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-card>
            </v-card>
          </v-col>
        </v-row>
        <h6 class="text-h6 text-center" v-else>
          Aún no hay participantes para este proceso
        </h6>
      </v-card-text>
      <v-dialog v-model="dialog" persistent>
        <v-card>
          <v-card-title>Lotes en los que participó</v-card-title>
          <v-card-text>
            <v-row class="FixedHeightContainer Content2 flex-wrap" justify="space-evenly">
              <v-card width="400px" elevation="0" v-for="participacion in participaciones" :key="participacion.nombre">
                <v-banner>
                  <v-avatar slot="icon" :color="informacionColor(participacion?.participacion_aceptada)
                    " size="40">
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on">{{
                          informacionIcono(
                            participacion?.participacion_aceptada
                          )
                        }}
                        </v-icon>
                      </template>
                      <span>{{
                        informacionMensaje(
                          participacion?.participacion_aceptada
                        )
                      }}</span>
                    </v-tooltip>
                  </v-avatar>
                  {{ participacion?.LotesProceso?.nombre }}
                </v-banner>
              </v-card>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn class="secondary" text block outlined
              @click="(dialog = false), (participaciones = [])">Cerrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </section>
</template>
<script>
export default {
  name: "ParticipantesComponents",
  props: {
    procesoData: {
      type: Object,
      default: () => { },
    },
  },
  data: () => ({
    ofertaHeaders: [
      {
        text: "Tipo de oferta",
        value: "tipo_oferta",
      },
      {
        text: "Fecha de recepción",
        value: "fecha_recepcion",
      },
      {
        text: "Subida por",
        value: "subida_por",
        sortable: false,
      },
      {
        text: "Creación del registro",
        value: "created_at",
        sortable: false,
      },
    ],
    dialog: false,
    participaciones: [],
    busqueda: "",
  }),
  computed: {
    ofertas_list() {
      return this.busqueda ? this.procesoData?.participantes?.filter(participante => {
        return participante.proveedor.toLowerCase().includes(this.busqueda.toLowerCase());
      }) : this.procesoData?.participantes;
    }
  },
  methods: {
    informacionIcono(value) {
      switch (value) {
        case true:
          return "mdi-check-circle";
        case false:
          return "mdi-alpha-x-circle";
        default:
          return "mdi-help-circle-outline";
      }
    },
    informacionMensaje(value) {
      switch (value) {
        case true:
          return "Aprobado";
        case false:
          return "Rechazado";
        default:
          return "Pendiente";
      }
    },
    informacionColor(value) {
      switch (value) {
        case true:
          return "success";
        case false:
          return "error";
        default:
          return "warning";
      }
    },
    async descargarDocumento(ruta) {
      const response =
        await this.services.PacProcesos.descargarDocumentoProceso({
          ruta: ruta,
          disk: "procesos",
        });
      if (!response) {
        this.temporalAlert({
          show: true,
          message: "Ocurrió un error al descargar el documento",
          type: "error",
        });

        return;
      }

      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });

      const file = new File([blob], "oferta", {
        type: response.headers["content-type"],
      });

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(file);
      link.download = "";
      link.click();
    },
    goToPerfil(id) {
      window.open(`${location.origin}/proveedor/${id}`, "_blank");
    },
  },
};
</script>
<style lang="scss" scoped>
:deep(.theme--light.v-sheet--outlined) {
  border: thin solid #404a7a !important;
}

.FixedHeightContainer {
  height: 600px;
}

.Content {
  height: 595px;
  overflow-y: auto;
  overflow-x: hidden;
}

.Content2 {
  overflow-y: auto;
  overflow-x: hidden;
  gap: 3%;
}
</style>
